/* Core */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

/* MUI */
import { Grid, Typography, Box, Button } from '@mui/material';

/* Custom styling */
import 'theme/base/Footer.scss';

import { setDataLeakUrl, setPrivacyStatementUrl } from 'helpers/Helpers';
import AccreditationPartners from 'components/common/AccreditationPartners';
import TermsConditions from './TermsConditions';
import { SecondaryLogo } from '../../../components/common/SecondaryLogo';
import FaqLink from './FaqLink';
import ComplaintsLink from './ComplaintsLink';

const Footer = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(!open);
  };

  return (
    <footer
      style={{
        overflow: 'hidden'
      }}
      className="main_footer"
      id="main_footer"
    >
      <Grid container className="accreditations">
        <AccreditationPartners />
        <Grid item>
          <SecondaryLogo />
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} className="copyright">
        <Grid container item xs={12} sm={12} md={12} alignItems="baseline" justifyContent="center">
          <Box>
            <Button style={{ textTransform: 'capitalize', color: 'black', fontWeight: '400' }} onClick={onClose}>
              {t('footer.copyright.link1', 'General conditions')}
            </Button>
          </Box>
          <Box>
            <Button
              target="_blank"
              component="a"
              style={{ textTransform: 'capitalize', color: 'black', fontWeight: '400' }}
              href={setPrivacyStatementUrl()}
            >
              {t('footer.copyright.link2', 'Privacy & Cookie Policy')}
            </Button>
          </Box>
          <Box>
            <Button
              target="_blank"
              component="a"
              style={{ textTransform: 'capitalize', color: 'black', fontWeight: '400' }}
              href={setDataLeakUrl()}
            >
              {t('footer.copyright.link4', 'Report data breach')}
            </Button>
          </Box>

          <ComplaintsLink />

          <FaqLink />

          <TermsConditions open={open} onClose={onClose} />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} justifyContent="center" alignItems="center" rel="noreferrer">
          <Typography style={{ marginRight: '5px' }} fontSize="13px" lineHeight="1.75">
            {t('footer.copyright.iso', 'ISO 27001 & ISO 9001 certified')}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} justifyContent="center" alignItems="center" padding="6px 8px">
          <Typography style={{ marginRight: '5px' }} fontSize="11px" lineHeight="1.75">
            {t('@ E-WISE')}
          </Typography>
          <Typography fontSize="11px" lineHeight="1.75">
            {t('footer.copyright.rights', 'All rights reserved')}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};
export default observer(Footer);
